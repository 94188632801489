import React, { useState, useEffect } from 'react';
import LeadHistoryModal from '../common/LeadHistoryModal';
import { Tooltip as TooltipLite } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { AGENT_ROLE_ID, ARO_ROLE_ID, APP_TYPE_STANDALONE, APP_TYPE_RENEWAL, APP_TYPE_AGENCY } from "@src/config/constant";

export default function LoanRibbenBox(props: any): ReturnType<React.FC> {
    const [tipOpen, setTipOpen] = useState(false);
    const [tipOpen1, setTipOpen1] = useState(false);
    const [tipOpen2, setTipOpen2] = useState(false);
    const [tipOpen3, setTipOpen3] = useState(false);
    const [tipOpen4, setTipOpen4] = useState(false);
    const [tipOpen5, setTipOpen5] = useState(false);
    const [tipOpen6, setTipOpen6] = useState(false);
    const [viewTimeLine, setViewTimeLine] = useState(false);
    const [selectedRemarkLead, setSelectedRemarkLead] = useState<any>(0);
    const leadDetailsState = useSelector((state: any) => state?.insurance?.leadDetail || {});
    const commonState = useSelector((state: any) => state?.common.common || {});

    useEffect(() => {
        const bodyClick = () => {
            setTipOpen(false);
            setTipOpen1(false);
            setTipOpen2(false);
            setTipOpen3(false);
            setTipOpen4(false);
            setTipOpen5(false);
            setTipOpen6(false);
        };
        document.addEventListener('mousedown', bodyClick);

        return () => {
            document.removeEventListener('mousedown', bodyClick);
        };
    }, []);

    /**
     * HAHNDLE HISTORY POPUP
     * @param type 
     * @param leadId 
     */
    const handleViewTimeLine = (type: boolean, leadId: any) => {
        setViewTimeLine(type);
        setSelectedRemarkLead(leadId);

    }

    let { leadId, insurance_type_name, status_name, sub_status_name, lead_type, dealer_name, dealer_gcd_code, document_status, application_type_id, source_type, customer_name, submitted_by, make_name, model_name, version_name, ucf_lead_id, ucf_lead_status, ucf_lead_substatus, insurance_partner, mobile, mfg_year, plate_no, officer_id, officer_name, manager_id, manger_name, territory_id, territory_name, national_id, national_name, p_id, p_name, role_id, agent_id, agent_name, aro_id, aro_name, employee_type } = leadDetailsState || {};
    //Here we find the employee type based on the employee ID using the
    const employeeTypeLabel = commonState && commonState.employee_type && commonState?.employee_type.find((type: any) => +type.value === employee_type)?.label;
    return (
        <React.Fragment>
            <div className="loan-bx-ribben">
                <ul className="maindetail-list clearfix ">
                    <li className="maindetail-item">
                        <h3 className="subheading">
                            Insurance Id
                        </h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title={leadId}>{leadId}</li>
                            </ul>
                        </div>
                    </li>


                    <li className="maindetail-item">
                        <h3 className="subheading">Application Submitted by</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                {
                                    lead_type && +lead_type === 3
                                        ?
                                        <li title={agent_name}>{agent_name}</li>
                                        :
                                        < li title="">
                                            <span className="truncate" title={submitted_by}>{submitted_by}</span>
                                            <span onClick={() => { setTipOpen4(true); }} data-tooltip-id="my-tooltip-4" title="" className="tooltio-ic"><i className="ic-info-icon1"></i>
                                            </span>

                                            {
                                                tipOpen4 && <div className='tooltip-outer'>

                                                    <TooltipLite
                                                        isOpen={tipOpen4}
                                                        className=" react-tooltip-lite"

                                                        id="my-tooltip-4"
                                                        place="bottom"
                                                        clickable={true}
                                                        render={
                                                            () =>
                                                                <div className=" controlled-example tooltip-main">
                                                                    <div className="tooltip-data">

                                                                        <span className="controlled-example_close-button" onClick={() => { setTipOpen4(false); }}><i className="ic-clearclose"></i></span>
                                                                        <ul className="user-detail">
                                                                            {
                                                                                (lead_type === 2) ? (<><li>
                                                                                    <span>Dealer Name</span>
                                                                                    <label>{dealer_name}</label>
                                                                                </li><li>
                                                                                        <span>GCD Code</span>
                                                                                        <label>{dealer_gcd_code}</label>
                                                                                    </li></>) : ''
                                                                            }
                                                                            {
                                                                                (role_id === AGENT_ROLE_ID) ? (<>
                                                                                    <li>
                                                                                        <span>Agent Name</span>
                                                                                        <label>{agent_name}</label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span>Agent ID</span>
                                                                                        <label>{agent_id}</label>
                                                                                    </li></>) : ''
                                                                            }
                                                                            {
                                                                                (role_id === AGENT_ROLE_ID || role_id === ARO_ROLE_ID) ? (<>
                                                                                    <li>
                                                                                        <span>Aro Name</span>
                                                                                        <label>{aro_name}</label>
                                                                                    </li><li>
                                                                                        <span>Aro ID</span>
                                                                                        <label>{aro_id}</label>
                                                                                    </li>
                                                                                </>) : ''
                                                                            }
                                                                            {
                                                                                (role_id !== AGENT_ROLE_ID && role_id !== ARO_ROLE_ID) ? (<>
                                                                                    <li>
                                                                                        <span>SO Name</span>
                                                                                        <label>{officer_name}</label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span>SO ID</span>
                                                                                        <label>{officer_id}</label>
                                                                                    </li>
                                                                                </>) : ''
                                                                            }
                                                                            <li>
                                                                                <span>SM Name</span>
                                                                                <label>{manger_name}</label>
                                                                            </li>
                                                                            <li>
                                                                                <span>SM ID</span>
                                                                                <label>{manager_id}</label>
                                                                            </li>
                                                                            <li>
                                                                                <span>TH Name</span>
                                                                                <label>{territory_name}</label>
                                                                            </li>
                                                                            <li>
                                                                                <span>TH ID</span>
                                                                                <label>{territory_id}</label>
                                                                            </li>
                                                                            <li>
                                                                                <span>NSH Name</span>
                                                                                <label>{national_name}</label>
                                                                            </li>
                                                                            <li>
                                                                                <span>NSH ID</span>
                                                                                <label>{national_id}</label>
                                                                            </li>
                                                                            <li>
                                                                                <span>President Name</span>
                                                                                <label>{p_name}</label>
                                                                            </li>
                                                                            <li>
                                                                                <span>President ID</span>
                                                                                <label>{p_id}</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                        }
                                                    />
                                                </div>
                                            }


                                        </li>
                                }

                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading">Customer Details</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li>
                                    <span className="buyer-txt" title={customer_name}>{customer_name}</span>
                                    <span onClick={() => { setTipOpen(true) }} data-tooltip-id="my-tooltip-0" title="" className="tooltio-ic"><i className="ic-info-icon1"></i>
                                    </span>

                                    {

                                        tipOpen && <div className='tooltip-outer'>

                                            <TooltipLite
                                                isOpen={tipOpen}
                                                className=" react-tooltip-lite"
                                                id="my-tooltip-0"
                                                place="bottom"
                                                clickable={true}
                                                render={() =>
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">

                                                            <span className="controlled-example_close-button" onClick={() => { setTipOpen(false); }}><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                                <li>
                                                                    <span>Customer Name</span>
                                                                    <label>{customer_name}</label>

                                                                </li>
                                                                <li>
                                                                    <span>Customer Mobile No.</span>
                                                                    <label>{mobile}</label>

                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }

                                            />
                                        </div>
                                    }

                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading">Car Details</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li>
                                    <span className="truncate" title={''}>{(make_name) ? make_name + ',' : ''}{(model_name) ? model_name + ',' : ''}{(version_name) ? version_name + ',' : ''}</span>
                                    <span onClick={() => { setTipOpen1(true) }} data-tooltip-id="my-tooltip-1" title="" className="tooltio-ic"><i className="ic-info-icon1"></i>
                                    </span>

                                    {
                                        tipOpen1 && <div className='tooltip-outer'>

                                            <TooltipLite
                                                isOpen={tipOpen1}
                                                className=" react-tooltip-lite"

                                                id="my-tooltip-1"
                                                place="bottom"
                                                clickable={true}
                                                render={() =>
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">

                                                            <span className="controlled-example_close-button" onClick={() => { setTipOpen1(false); }}><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                                <li>
                                                                    <span>Make</span>
                                                                    <label>{make_name}</label>
                                                                </li>
                                                                <li>
                                                                    <span>Model</span>
                                                                    <label>{model_name}</label>
                                                                </li>
                                                                <li>
                                                                    <span>Variant</span>
                                                                    <label>{version_name}</label>
                                                                </li>
                                                                <li>
                                                                    <span>Manufacturing Year</span>
                                                                    <label>{mfg_year}</label>
                                                                </li>
                                                                <li>
                                                                    <span>Plate Number</span>
                                                                    <label>{plate_no}</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }

                                            />
                                        </div>
                                    }


                                </li>

                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading">Insurance Partner Details</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list" title={insurance_partner}>
                                <li>
                                    <span className="truncate">{insurance_partner}</span>
                                    <span onClick={() => { setTipOpen5(true) }} data-tooltip-id='my-tooltip-5' title="" className="tooltio-ic"><i className="ic-info-icon1"></i></span>

                                </li>
                                {
                                    tipOpen5 &&
                                    <div className='tooltip-outer'>
                                        <TooltipLite
                                            isOpen={tipOpen5}
                                            className=" react-tooltip-lite"

                                            id="my-tooltip-5"
                                            place="bottom"
                                            clickable={true}
                                            render={() =>
                                                <div className="controlled-example tooltip-main">
                                                    <div className="tooltip-data">

                                                        <span className="controlled-example_close-button" onClick={() => { setTipOpen5(false); }}><i className="ic-clearclose"></i></span>
                                                        <ul className="user-detail">
                                                            <li>
                                                                <span>Insurance Partner</span>
                                                                <label>{insurance_partner}</label>
                                                            </li>
                                                            <li>
                                                                <span>Insurance Type</span>
                                                                <label>{insurance_type_name}</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            }

                                        />
                                    </div>
                                }

                            </ul>

                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading">Source Type</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li>
                                    <span className="truncate" title={source_type}>{source_type}</span>
                                    <span onClick={() => { setTipOpen3(true) }} data-tooltip-id='my-tooltip-3' title="" className="tooltio-ic"><i className="ic-info-icon1"></i>
                                    </span>


                                    {
                                        tipOpen3 && <div className='tooltip-outer'>
                                            <TooltipLite
                                                isOpen={tipOpen3}
                                                className=" react-tooltip-lite"

                                                id="my-tooltip-3"
                                                place="bottom"
                                                clickable={true}
                                                render={() =>
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">

                                                            <span className="controlled-example_close-button" onClick={() => { setTipOpen3(false); }}><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                                <li>
                                                                    <span>Application Submitted By</span>
                                                                    <label>{submitted_by}</label>
                                                                </li>
                                                                <li>
                                                                    <span>Source Type</span>
                                                                    <label>{source_type}</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }

                                            />
                                        </div>
                                    }

                                </li>
                            </ul>
                        </div>
                    </li>
                    {
                        (lead_type === 2) ?
                            <li className="maindetail-item">
                                <h3 className="subheading">Dealer Details</h3>
                                <div className="sub-value">
                                    <ul className="sub-value-list">
                                        <li>
                                            <span className="truncate" title={dealer_name}>{dealer_name}</span>
                                            <span onClick={() => { setTipOpen2(true) }} title="" data-tooltip-id='my-tooltip-2' className="tooltio-ic"><i className="ic-info-icon1"></i>
                                            </span>

                                            {
                                                tipOpen2 &&
                                                <div className='tooltip-outer'>
                                                    <TooltipLite
                                                        isOpen={tipOpen2}
                                                        className=" react-tooltip-lite"

                                                        id="my-tooltip-2"
                                                        place="bottom"
                                                        clickable={true}
                                                        render={() =>
                                                            <div className="controlled-example tooltip-main">
                                                                <div className="tooltip-data">

                                                                    <span className="controlled-example_close-button" onClick={() => { setTipOpen2(false); }}><i className="ic-clearclose"></i></span>
                                                                    <ul className="user-detail">
                                                                        <li>
                                                                            <span>Dealer Name</span>
                                                                            <label>{dealer_name}</label>
                                                                        </li>
                                                                        <li>
                                                                            <span>Dealer GCD Code</span>
                                                                            <label>{dealer_gcd_code}</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        }

                                                    />
                                                </div>
                                            }



                                        </li>
                                    </ul>
                                </div>
                            </li> : ''
                    }

                    <li className="maindetail-item">
                        <h3 className="subheading">Case Status</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title={status_name}><span className="truncate">{status_name}</span></li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <h3 className="subheading">Case Sub Status</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title={sub_status_name}><span className="truncate">{sub_status_name}</span></li>
                            </ul>
                        </div>
                    </li>
                    <li className="maindetail-item">
                        <h3 className="subheading">Doc Status</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title={document_status ?? "Pending"}><span className="truncate">{document_status ?? "Pending"}</span></li>
                            </ul>
                        </div>
                    </li>
                    {
                        (application_type_id === 1) ?
                            <li className="maindetail-item">
                                <h3 className="subheading">UCF Loan ID</h3>
                                <div className="sub-value">
                                    <ul className="sub-value-list">
                                        <li title={ucf_lead_id}>{ucf_lead_id}</li>
                                    </ul>
                                </div>
                            </li> : ''
                    }
                    {
                        (application_type_id === 1) ?
                            <li className="maindetail-item">
                                <h3 className="subheading">UCF Status</h3>
                                <div className="sub-value">
                                    <ul className="sub-value-list">
                                        <li title={ucf_lead_status}>{ucf_lead_status}</li>
                                    </ul>
                                </div>
                            </li> : ''
                    }
                    {
                        (application_type_id === 1) ?
                            <li className="maindetail-item">
                                <h3 className="subheading">UCF Sub Status</h3>
                                <div className="sub-value">
                                    <ul className="sub-value-list">
                                        <li title={ucf_lead_substatus}><span className="truncate">{ucf_lead_substatus}</span> </li>
                                    </ul>
                                </div>
                            </li> : ''
                    }
                    {/* lead type = 3  for web, it show only when request is created by mobile app */}
                    {(+lead_type === 1 && employeeTypeLabel) &&
                        <li className="maindetail-item">
                            <h3 className="subheading">Employment Type</h3>
                            <div className="sub-value">
                                <ul className="sub-value-list">
                                    <li title={employeeTypeLabel}><span className="truncate">{employeeTypeLabel}</span></li>
                                </ul>
                            </div>
                        </li>
                    }

                    {([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(+application_type_id)) &&
                        <li className="maindetail-item">
                            <h3 className="subheading">Payment Status</h3>
                            <div className="sub-value">
                                <ul className="sub-value-list">
                                    <li title={((leadDetailsState?.premium_amount || leadDetailsState?.inbound_amount || leadDetailsState?.pay_from_cust_to_partner_broker_id) ? "Paid" : "Pending")}><span className="truncate">{((leadDetailsState?.premium_amount || leadDetailsState?.inbound_amount || leadDetailsState?.pay_from_cust_to_partner_broker_id) ? "Paid" : "Pending")}</span></li>
                                </ul>
                            </div>
                        </li>
                    }

                    {/* {([APP_TYPE_AGENCY].includes(+application_type_id)) &&
                        <li className="maindetail-item">
                            <h3 className="subheading">Payment Status</h3>
                            <div className="sub-value">
                                <ul className="sub-value-list">
                                    <li title={(( leadDetailsState?.inbound_amount || leadDetailsState?.insurance_agency_payment_id) ? "Paid" : "Pending")}><span className="truncate">{(( leadDetailsState?.inbound_amount || leadDetailsState?.insurance_agency_payment_id) ? "Paid" : "Pending")}</span></li>
                                </ul>
                            </div>
                        </li>
                    } */}


                    {
                        ([APP_TYPE_AGENCY].includes(+application_type_id)) &&

                        <li className="maindetail-item">
                            <h3 className="subheading">Agency Details</h3>
                            <div className="sub-value">
                                <ul className="sub-value-list">
                                    <li>
                                        <span className="truncate" title={leadDetailsState?.agency_name}>{leadDetailsState?.agency_name}</span>
                                        <span onClick={() => { setTipOpen6(true) }} data-tooltip-id='my-tooltip-6' title="" className="tooltio-ic"><i className="ic-info-icon1"></i>
                                        </span>

                                        {
                                            tipOpen6 && <div className='tooltip-outer'>


                                                <TooltipLite
                                                    isOpen={tipOpen6}
                                                    className=" react-tooltip-lite"

                                                    id="my-tooltip-6"
                                                    place="bottom"
                                                    clickable={true}
                                                    render={() =>
                                                        <div className="controlled-example tooltip-main">
                                                            <div className="tooltip-data">

                                                                <span className="controlled-example_close-button" onClick={() => { setTipOpen6(false); }}><i className="ic-clearclose"></i></span>
                                                                <ul className="user-detail">
                                                                    <li>
                                                                        <span>Agent Name</span>
                                                                        <label>{leadDetailsState?.agency_name}({leadDetailsState?.agency_id})</label>
                                                                    </li>
                                                                    <li>
                                                                        <span>Agent Status</span>
                                                                        <label style={{"textTransform": "capitalize"}}>{leadDetailsState?.agency_status_name}</label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }

                                                />
                                            </div>
                                        }

                                    </li>
                                </ul>
                            </div>
                        </li>

                    }


                    {/* Here we mamage the button design, come in right corner,if lead_type = 2 dealer info, */}
                    {/* {(+lead_type === 1 && +application_type_id === 1) ?
                        <li className="ribbon-view-history-btn">
                            <button type="button" className="btn-primary-accent history-btn" onClick={() => handleViewTimeLine(true, leadId)}>View History
                                <i className="ic-arrow_forward"></i>
                            </button>
                        </li>
                        : */}
                    <li className="maindetail-item">
                        <button type="button" className="btn-primary-accent history-btn" onClick={() => handleViewTimeLine(true, leadId)}>View History
                            <i className="ic-arrow_forward"></i>
                        </button>
                    </li>
                    {/* }     */}

                </ul>
            </div>

            {viewTimeLine && <LeadHistoryModal viewTimeLine={viewTimeLine} selectedRemarkLead={selectedRemarkLead} handleViewTimeLine={(action: boolean, leadId: any) => handleViewTimeLine(action, leadId)} />}
        </React.Fragment >
    );
};




